import { useState, useEffect } from 'react';
import axios from 'axios';
import { APIConfig } from "../Config";
import { Member } from "../interface/Member";

interface Links {
	first?: string;
	last?: string;
	prev?: string;
	next?: string;
}

interface ApiResponse {
	data: Member[];
	meta: {
		links: Links;
	};
}

export const useMembers = (page: number = 1, perPage: number = 6) => {
	const [data, setData] = useState<Member[]>([]);
	const [links, setLinks] = useState<Links>({});
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const fetchMembers = async () => {
			setLoading(true);
			const config = {
				headers: APIConfig.getAuthHeaders(),
				params: { page, perPage }
			};

			try {
				const response = await axios.get<ApiResponse>(`${APIConfig.baseURL}/members`, config);
				setData(response.data.data);
				setLinks(response.data.meta.links);
				setError(null);
			} catch (err) {
				setError('Failed to fetch members');
				console.error(err);
			} finally {
				setLoading(false);
			}
		};

		fetchMembers();
	}, [page, perPage]);

	useEffect(() => {
	}, [data, links]); // This useEffect will run after data or links are updated.

	return { data, links, loading, error };
};
