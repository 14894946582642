// services/toastService.ts
import { toast, ToastOptions, TypeOptions } from 'react-toastify';

// Generic function to show toast with options
const showToast = (message: string, options?: ToastOptions): void => {
	toast(message, options);
};

// Specific functions for each type of toast
const showSuccessToast = (message: string): void => {
	showToast(message, { type: "success" });
};

const showErrorToast = (message: string): void => {
	showToast(message, { type: "error" });
};

const showInfoToast = (message: string): void => {
	showToast(message, { type: "info" });
};

const showWarningToast = (message: string): void => {
	showToast(message, { type: "warning" });
};

export { showSuccessToast, showErrorToast, showInfoToast, showWarningToast };
