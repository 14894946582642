import React from 'react';
import { useResetPassword } from '../../api/useResetPassword';  // Ensure the path is correct
import { showSuccessToast, showErrorToast } from '../../services/toastService';
import {useNavigate} from "react-router-dom"; // Adjust path as necessary

const ResetPasswordPage: React.FC = () => {
	const navigate = useNavigate();
	const { email, setEmail, resetPassword, isLoading } = useResetPassword();
	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const success = await resetPassword();
		if (success) {
			setEmail('');  // Clear the email input if the reset is successful
			showSuccessToast('Check your email for reset instructions'); // Show success toast
			navigate('/login');  // Redirect to login page
		} else {
			showErrorToast('Failed to send reset link. Please try again.'); // Show error toast
		}
	};

	return (
		<div className="min-h-screen flex items-center justify-center bg-gray-100">
			<div className="max-w-md w-full bg-white p-6 rounded-md shadow-md">
				<h1 className="text-xl font-bold mb-4 text-center">Reset Password</h1>
				<form onSubmit={handleSubmit} className="space-y-6">
					<div>
						<label className="text-sm font-bold text-gray-600 block" htmlFor="email">
							Please enter your email address:
						</label>
						<input
							className="mt-1 px-3 py-2 bg-gray-200 rounded border border-gray-300 w-full focus:outline-none focus:shadow-outline"
							id="email"
							type="email"
							placeholder="Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
					</div>
					<div>
						<button
							className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
							type="submit"
							disabled={isLoading}
						>
							{isLoading ? 'Sending...' : 'Send Reset Link'}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ResetPasswordPage;
