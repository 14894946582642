import React, { useState, useEffect, ReactNode } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { CheckAuth } from '../api/CheckAuth';

interface PrivateRouteProps {
	children?: ReactNode; // Children is optional
}
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
	const [authStatus, setAuthStatus] = useState<{ checked: boolean, isAuthenticated: boolean }>({
		checked: false, // Indicates whether the auth check has completed
		isAuthenticated: false, // Indicates whether the user is authenticated
	});

	const location = useLocation();

	useEffect(() => {
		const authenticate = async () => {
			const authResult = await CheckAuth();
			setAuthStatus({
				checked: true, // Mark as checked regardless of the result
				isAuthenticated: authResult.isAuthenticated,
			});
		};

		authenticate();
	}, []);

	if (!authStatus.checked) {
		// Display a loading indicator until the check is complete
		return <div>Loading...</div>;
	}

	return authStatus.isAuthenticated ? <Outlet /> : <Navigate to="/login" replace state={{ from: location }} />;
};

export default PrivateRoute;
