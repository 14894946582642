import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MobileMenu from "./MobileMenu"
import DesktopMenu from './DesktopMenu';
import ToggleMenuButton from './ToggleMenuButton'
import { useAuth } from "../../api/AuthProvider";

const Navbar: React.FC = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const navigate = useNavigate(); // Hook to programmatically navigate
	const { logout } = useAuth();

	const handleToggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const handleLogout = async () => {
		try {
			logout(); // Ensuring logout function is called properly
			navigate('/login'); // Redirect the user to the login page after logout
		} catch (error) {
			console.error('Failed to logout:', error);
			// Optionally handle errors (e.g., show a notification)
		}
	};


	return (
		<nav className="bg-gray-800 fixed top-0 left-0 w-full z-10">
			<div className="px-2 sm:px-6 lg:px-8">
				<div className="relative flex items-center justify-between h-16">
					<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
						<ToggleMenuButton isMenuOpen={isMenuOpen} handleToggleMenu={handleToggleMenu} />
					</div>
					<div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
						<div className="flex-shrink-0 flex items-center">
							<span className="text-white font-semibold text-lg lg:text-xl block lg:hidden">Kyokushin Kenbukai PH</span>
							<span className="text-white font-semibold text-lg lg:text-xl hidden lg:block">Kyokushin Kenbukai Philippines</span>
						</div>
						{/* Desktop navigation items */}
						<DesktopMenu handleLogout={handleLogout} />
					</div>
				</div>
			</div>
			{/* Mobile menu */}
			<MobileMenu isMenuOpen={isMenuOpen} handleLogout={handleLogout} handleToggleMenu={handleToggleMenu} />
		</nav>
	);
};

export default Navbar;
