import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from '../routes/PrivateRoute';
import LoginPage from "../pages/LoginPage";
import Layout from "../components/Layout";
import ProfilePage from "../pages/ProfilePage";
import PageNotFound from "../pages/PageNotFound";
import HomePage from "../pages/HomePage";
import MembersPage from "../pages/MembersPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import UpdatePasswordPage from "../pages/UpdatePasswordPage";
import TransactionsPage from "../pages/TransactionsPage";
import RegisterPage from "../pages/RegisterPage";

const AppRoutes: React.FC = () => (
	<Router>
		<Routes>
			<Route path="/login" element={<LoginPage />} />
			<Route path="/reset-password" element={<ResetPasswordPage />} />
			<Route path="/update-password/:token" element={<UpdatePasswordPage />} />
			<Route path="/register" element={<RegisterPage />} />
			{/* Wrap the Layout and its child routes with PrivateRoute */}
			<Route element={<PrivateRoute />}>
				<Route path="/" element={<Layout />}>
					{/* Now, all nested routes within Layout are protected */}
					<Route path="/" element={<HomePage />} />
					<Route path="profile" element={<ProfilePage />} />
					<Route path="members" element={<MembersPage />} />
					<Route path="transactions" element={<TransactionsPage />} />
					{/* More protected routes here */}
				</Route>
			</Route>
			<Route path="*" element={<PageNotFound />} />
		</Routes>
	</Router>
);

export default AppRoutes;
