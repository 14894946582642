// app/javascript/hooks/useTrainings.ts
import { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { APIConfig } from '../Config';

const API_URL = `${APIConfig.baseURL}/trainings`; // Replace with your actual API URL

interface Training {
	id: string;
	description: string;
	fullname: string;
	image_url: string;
}

interface ApiResponse {
	data: Training[];
}

const useTrainings = () => {
	const [trainings, setTrainings] = useState<Training[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const fetchTrainings = async () => {
			const config = {
				headers: APIConfig.getAuthHeaders(),
			};

			try {
				const response = await axios.get<ApiResponse>(API_URL, config);
				setTrainings(response.data.data); // Assuming "data" is the root field from API response
			} catch (err) {
				// Check if it's an Axios error
				if (axios.isAxiosError(err)) {
					const axiosError = err as AxiosError;
					if (axiosError.response) {
						setError(`Error: ${axiosError.response.status} - ${axiosError.response.statusText}`);
					} else {
						setError(`Network error: ${axiosError.message}`);
					}
				} else {
					// Handle other unknown errors
					setError(`An unknown error occurred: ${err}`);
				}
			} finally {
				setLoading(false);
			}
		};

		fetchTrainings();
	}, []);

	return { trainings, loading, error };
};

export default useTrainings;
