export const APIConfig = {
	baseURL: process.env.REACT_APP_API_URL,
	getAuthHeaders: (): Record<string, string> => {
		const token = localStorage.getItem('KENBUKAI-TOKEN');
		const apiKey = process.env.REACT_APP_API_KEY; // Ensure this variable is defined in your .env file

		const headers: Record<string, string> = {};

		if (token) {
			headers['Authorization'] = `Bearer ${token}`;
		} else {
			console.error("Authentication token is not available. Using API Key instead.");
			if (apiKey) {
				headers['X-Api-Key'] = apiKey;
			} else {
				console.error("API Key is also not available. Check your configuration.");
				// Handle the case where neither token nor API key is available
			}
		}

		return headers;
	},
};