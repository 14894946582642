import axios from 'axios';
import { APIConfig } from "../Config";
import { User } from '../interface/User';

interface ApiResponse {
	data: User;  // Assuming the API nests user data under a 'data' property
}

interface AuthCheckResponse {
	isAuthenticated: boolean;
	user?: User;
}

export const CheckAuth = async (): Promise<AuthCheckResponse> => {
	try {
		const response = await axios.get<ApiResponse>(`${APIConfig.baseURL}/me`, {
			headers: APIConfig.getAuthHeaders(),
		});

		// Check if the user data exists and is in the expected format
		if (response.data && typeof response.data.data === 'object') {  // Adjusted to access nested 'data'
			return { isAuthenticated: true, user: response.data.data };
		} else {
			console.error("Invalid user data received:", response.data);
			return { isAuthenticated: false };
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// Detailed error handling
			console.error("Error checking authentication:", error.message);
			if (error.response) {
				console.error("Status code:", error.response.status, "Data:", error.response.data);
			}
		} else {
			console.error("Unexpected error:", error);
		}
		return { isAuthenticated: false };
	}
};
