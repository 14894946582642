import React, { useState } from 'react';
import Modal from '../../components/Modal';
import useTransactions from '../../api/useTransactions';
import { Transaction } from '../../interface/Transaction';
import qrCode from '../../assets/qr-code.jpg'; // Adjust the path as necessary

const TransactionsPage: React.FC = () => {
	const { transactions, isLoading, error, addTransaction } = useTransactions();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [newTransaction, setNewTransaction] = useState<Partial<Transaction>>({});

	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setNewTransaction(prev => ({
			...prev,
			[name]: name === 'amount' ? Number(value) : value,
		}));
	};

	const handleAddTransaction = async () => {
		if (newTransaction.amount && newTransaction.ref_number) {
			await addTransaction({
				amount: newTransaction.amount,
				ref_number: newTransaction.ref_number,
			});

			setNewTransaction({});
			closeModal();
		}
	};

	return (
		<div className="container mx-auto max-w-3xl p-6 bg-white shadow-lg rounded-lg my-8">
			<h1 className="text-3xl font-bold mb-6">Transactions</h1>
			<button
				className="bg-blue-600 text-white font-semibold py-2 px-6 rounded-lg hover:bg-blue-700 mb-8"
				onClick={openModal}
			>
				Request Load
			</button>

			{isLoading ? (
				<p className="text-gray-600">Loading transactions...</p>
			) : error ? (
				<p className="text-red-500">{error}</p>
			) : (
				<ul className="space-y-4">
					{transactions.map(txn => (
						<li key={txn.id} className="p-4 bg-gray-50 border border-gray-200 rounded-lg shadow-sm">
							<div className="flex justify-between items-center">
								<span className="text-lg font-medium text-gray-700">{txn.amount} PHP</span>
								<span className={`px-3 py-1 rounded-full text-sm font-semibold ${txn.status === 'completed' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                                    {txn.status}
                                </span>
							</div>
							<p className="mt-2 text-gray-600">{txn.ref_number}</p>
						</li>
					))}
				</ul>
			)}

			{isModalOpen && (
				<Modal title="Send Request" onClose={closeModal}>
					<label className="block mb-4">
						Amount:
						<input
							type="number"
							name="amount"
							value={newTransaction.amount || ''}
							onChange={handleInputChange}
							className="w-full p-3 border border-gray-300 rounded-lg mt-1"
						/>
					</label>
					<label className="block mb-4">
						Ref Number:
						<input
							type="text"
							name="ref_number"
							value={newTransaction.ref_number || ''}
							onChange={handleInputChange}
							className="w-full p-3 border border-gray-300 rounded-lg mt-1"
						/>
					</label>
					<div className="flex justify-center mb-6">
						<img src={qrCode} alt="QR Code" className="w-64 h-64"/>
					</div>
					<div className="flex justify-center mb-6">
						<a href={qrCode} download="qr-code.jpg" className="text-blue-600 hover:underline">
							Download QR
						</a>
					</div>
					<div className="flex justify-end gap-3 mt-6">
						<button
							onClick={handleAddTransaction}
							className="bg-green-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-green-600"
						>
							Submit
						</button>
						<button
							onClick={closeModal}
							className="bg-red-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-red-600"
						>
							Cancel
						</button>
					</div>
				</Modal>
			)}
		</div>
	);
};

export default TransactionsPage;
