// hooks/useTransactions.ts
import { useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import { Transaction } from '../interface/Transaction';
import { APIConfig } from "../Config";

const API_URL = `${APIConfig.baseURL}/transactions`; // Replace with your actual API URL

interface ApiResponse {
	data: Transaction[];
}

interface TransactionResponse {
	data: Transaction
}

const useTransactions = (limit: number = 10) => {
	const [transactions, setTransactions] = useState<Transaction[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	// Function to load transactions
	const loadTransactions = async () => {
		setIsLoading(true);
		setError(null);
		const config = {
			headers: APIConfig.getAuthHeaders(),
		};
		try {
			const response = await axios.get<ApiResponse>(API_URL, config);
			const allTransactions = response.data.data;
			setTransactions(allTransactions.slice(0, limit));
		} catch (err) {
			setError('Error loading transactions');
		} finally {
			setIsLoading(false);
		}
	};

	// Function to add a transaction via the API
	const addTransaction = async (transaction: Partial<Transaction>) => {
		const config = {
			headers: APIConfig.getAuthHeaders(),
		};

		try {
			// Use `Partial<Transaction>` for a flexible input and match snake_case
			const response = await axios.post<TransactionResponse>(API_URL, transaction, config);
			setTransactions(prev => [response.data.data, ...prev].slice(0, limit));
		} catch (err) {
			setError('Error adding transaction');
		}
	};

	// Load transactions initially
	useEffect(() => {
		loadTransactions();
	}, []);

	return { transactions, isLoading, error, addTransaction };
};

export default useTransactions;
