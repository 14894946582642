// DesktopMenu.tsx
import React from 'react';
import { Link } from 'react-router-dom';

interface DesktopMenuProps {
	handleLogout: () => void;
}

const DesktopMenu: React.FC<DesktopMenuProps> = ({ handleLogout }) => {
	return (
		<div className="hidden sm:block sm:ml-6">
			<div className="flex space-x-4">
				<Link to="/" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Home</Link>
				<Link to="/profile" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Profile</Link>
				<Link to="/members" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Members</Link>
				<Link to="/transactions" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Transactions</Link>
				{/* Adding the logout link */}
				<button onClick={handleLogout} className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Logout</button>
			</div>
		</div>
	);
};

export default DesktopMenu;
