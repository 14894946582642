import { useState, useEffect } from 'react';
import axios from 'axios';
import { APIConfig } from '../Config';

const API_URL = `${APIConfig.baseURL}/v1/branches`; // Replace with your actual API URL

interface Dojo {
	id: string;
	name: string;
	dojo_id: string;
}

interface Branch {
	id: string;
	name: string;
	branch_dojos: Dojo[];
}

interface BranchesResponse {
	data: Branch[];
}

const useBranches = () => {
	const [branches, setBranches] = useState<Branch[]>([]);
	const [selectedBranch, setSelectedBranch] = useState<Branch | null>(null);
	const [dojos, setDojos] = useState<Dojo[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const fetchBranches = async () => {
			const config = {
				headers: APIConfig.getAuthHeaders(),
			};

			try {
				const response = await axios.get<BranchesResponse>(API_URL, config);
				setBranches(response.data.data);
			} catch (err) {
				if (axios.isAxiosError(err) && err.response) {
					setError(err.response.statusText);
				} else if (err instanceof Error) {
					setError(err.message);
				} else {
					setError('An unknown error occurred');
				}
			} finally {
				setLoading(false);
			}
		};

		fetchBranches();
	}, []);

	const selectBranch = (branchId: string) => {
		const branch = branches.find(b => b.id === branchId) || null;
		setSelectedBranch(branch);
		setDojos(branch ? branch.branch_dojos : []);
	};

	return { branches, selectedBranch, dojos, loading, error, selectBranch };
};

export default useBranches;
