import React, { useState, useRef } from 'react';
import { User } from '../../interface/User';
import axios, { AxiosError } from 'axios';
import { APIConfig } from "../../Config";
import { showSuccessToast } from '../../services/toastService';
import { FaCamera, FaSave } from 'react-icons/fa'; // Import the camera icon
import Modal from '../../components/Modal'
import QRCode from 'qrcode.react';

const ProfileData: React.FC<User> = ({
	                                     id,
	                                     email,
	                                     fullname,
	                                     required_days_of_training_in_text,
	                                     membership_number,
	                                     branches,
	                                     dojos,
	                                     rank,
	                                     image,
	                                     wallet_balance
                                     }) => {
	// Local state to handle the image preview
	const [previewImage, setPreviewImage] = useState<string>(image || 'src/assets/logo.png');
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [isFileSelected, setIsFileSelected] = useState<boolean>(false);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [isQrCodeModalOpen, setIsQrCodeModalOpen] = useState(false);

	const handleQrCodeClick = () => {
		setIsQrCodeModalOpen(true);
	};

	// Function to handle image selection and preview
	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setPreviewImage(reader.result as string);
				setSelectedFile(file);
				setIsFileSelected(true);
			};
			reader.onerror = (error) => {
				console.error('Error reading file:', error);
			};
			reader.readAsDataURL(file);
		} else {
			setPreviewImage('src/assets/logo.png');
			setSelectedFile(null);
			setIsFileSelected(false);
		}
	};

	const triggerFileInput = () => {
		fileInputRef.current?.click();  // Programmatically click the file input
	};


	// Function to handle the actual image upload to the server
	const handleUpload = async () => {
		if (selectedFile) {
			const formData = new FormData();
			formData.append('image', selectedFile);
			formData.append('id', id);

			const config = {
				headers: {
					...APIConfig.getAuthHeaders(),
					'Content-Type': 'multipart/form-data'
				}
			};

			try {
				const response = await axios.post(`${APIConfig.baseURL}/members/${id}/upload_image`,
					formData, config
				);
				setPreviewImage(response.data.data.image);
				setIsFileSelected(false);
				showSuccessToast("Upload image successfully")
			} catch (error) {
				const axiosError = error as AxiosError;  // Type assertion here
				if (axiosError.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.error('Upload failed:', axiosError.response.data);
				} else if (axiosError.request) {
					// The request was made but no response was received
					console.error('No response received:', axiosError.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.error('Error setting up file upload:', axiosError.message);
				}
			}
		} else {
			console.log('No file selected');
		}
	};




	return (
		<div>
			<div className="container mx-auto max-w-4xl bg-white shadow-md hover:shadow-lg transition-shadow duration-300 rounded-lg overflow-hidden my-0">
				<div className="sm:flex">
					<div className="relative">
						<img
							className="object-cover w-full sm:h-52 sm:w-52"
							src={previewImage} // Provide a default image path
							alt={fullname}
						/>
						<input
							ref={fileInputRef}
							type="file"
							accept="image/*"
							onChange={handleImageChange}
							className="hidden"
							style={{ height: '100%', width: '100%' }} // Ensures the input covers the image for easy clicking
						/>
						<button
							onClick={isFileSelected ? handleUpload : triggerFileInput}
							className="absolute bottom-0 right-0 mb-4 mr-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
						>
							{isFileSelected ? <FaSave className="text-lg" /> : <FaCamera className="text-lg" />}
						</button>
					</div>
					<div className="p-8 flex flex-col justify-between">
						<div>
							<div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
								<h1 className="text-2xl font-semibold text-gray-900 mb-2 sm:mb-0">
									{fullname}
								</h1>
							</div>
							<p className="text-gray-600 mt-1">{required_days_of_training_in_text}</p>
							<p className="text-lg font-medium text-gray-900">Wallet Balance: PHP {wallet_balance}</p>
						</div>
						<div className="flex flex-col-reverse sm:flex-row sm:items-center sm:justify-between">
						<a
								href="#view-qr"
								onClick={handleQrCodeClick}
								className="text-blue-500 hover:text-blue-700 text-sm font-bold mt-4 sm:mt-0 sm:self-center"
							>
								View my QR Code
							</a>
							<div className="mt-4">
								<h3 className="text-sm font-semibold text-gray-600">Branches</h3>
								<p className="text-gray-900">{branches.map(branch => branch.name).join(', ')}</p>
							</div>
							<div className="mt-4">
								<h3 className="text-sm font-semibold text-gray-600">Dojos</h3>
								<p className="text-gray-900">{dojos.map(dojo => dojo.name).join(', ')}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				{isQrCodeModalOpen && (
					<Modal title={fullname} onClose={() => setIsQrCodeModalOpen(false)}>
						<div className="flex justify-center">
							<QRCode value={membership_number} size={256} level="H" />
						</div>
					</Modal>
				)}
			</div>
		</div>
	);
};

export default ProfileData;
