import { useState } from 'react';
import axios from 'axios';
import { APIConfig } from "../Config";

interface UseResetPasswordResult {
	email: string;
	setEmail: (email: string) => void;
	resetPassword: () => Promise<boolean>; // Return success status
	isLoading: boolean;
}

export function useResetPassword(): UseResetPasswordResult {
	const [email, setEmail] = useState<string>('');
	const [isLoading, setLoading] = useState<boolean>(false);

	const resetPassword = async (): Promise<boolean> => {
		setLoading(true);
		try {
			const response = await axios.post(`${APIConfig.baseURL}/password_resets`, { email });
			console.log(response.data); // Optionally log the response data here
			return true; // Return true on successful request
		} catch (error) {
			console.error('Error resetting password:', error); // Optionally log the error
			return false; // Return false on failure
		} finally {
			setLoading(false);
		}
	};

	return { email, setEmail, resetPassword, isLoading };
}
