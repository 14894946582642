// src/MemberTable.tsx
import React from 'react';
import { Member } from '../../interface/Member';

interface MemberTableProps {
	members: Member[];
}

const MemberTable: React.FC<MemberTableProps> = ({ members }) => {
	return (
		<div className="overflow-x-auto">
			<table className="min-w-full table-auto">
				<thead className="bg-gray-50">
				<tr>
					<th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Avatar</th>
					<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fullname</th>
				</tr>
				</thead>
				<tbody className="bg-white divide-y divide-gray-200">
				{members.map(member => (
					<tr key={member.id}>
						<td className="px-6 py-4 whitespace-nowrap">
							<img src={member.image} alt={member.fullname} className="h-20 w-20 rounded-full"/>
						</td>
						<td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
							{member.fullname}
							<div className="text-xs text-gray-500 mt-2">Click for more</div>
						</td>
					</tr>
				))}
				</tbody>
			</table>
		</div>
	);
};

export default MemberTable;
