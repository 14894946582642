import React, { useState, useEffect } from 'react';
import { CheckAuth } from '../../api/CheckAuth'; // Assuming the path is correct
import ProfileData from './ProfileData'; // Assuming the path is correct
import { User } from '../../interface/User'

interface UserState {
	isAuthenticated: boolean;
	user: User;
}

const ProfilePage: React.FC = () => {
	const [userState, setUserState] = useState<UserState | null>(null);
	const [isLoading, setIsLoading] = useState(true); // Added to explicitly track loading state

	// Adjusting the fallback userState for unauthenticated users
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CheckAuth();
				console.log("UserData:", response.user);
				if (response.isAuthenticated && response.user) {
					setUserState({
						isAuthenticated: true,
						user: response.user,
					});
				} else {
					setUserState(null); // Setting userState to null if not authenticated
				}
			} catch (error) {
				console.error("Error fetching authentication data:", error);
				// Optionally update the UI to show an error message
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, []);

// Adjust your conditional rendering based on the null check for userState
	if (isLoading) {
		return <p>Loading...</p>;
	}

	if (!userState) { // This now checks if userState is null, implying not authenticated or an error occurred
		return <p>User not authenticated or an error occurred.</p>;
	}


	// Now, it's guaranteed that user is authenticated and userState is not null
	return (
		<div>
			<ProfileData
				id={userState.user.id}
				email={userState.user.email}
				fullname={userState.user.fullname || 'No fullname provided'}
				required_days_of_training_in_text={userState.user.required_days_of_training_in_text}
				membership_number={userState.user.membership_number}
				branches={userState.user.branches}
				dojos={userState.user.dojos}
				rank={userState.user.rank}
				image={userState.user.image}
				days_of_training={userState.user.days_of_training}
				likes_count={userState.user.likes_count}
				wallet_balance={userState.user.wallet_balance}
			/>
		</div>
	);
};

export default ProfilePage;