// Layout.tsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../Navbar'; // Assuming you have a Navbar component

const Layout: React.FC = () => (
	<>
		<Navbar />
		<main className="pt-16 md:pt-20">
			<Outlet/> {/* Nested routes will be rendered here */}
		</main>
	</>
);

export default Layout;
