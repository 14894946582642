import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { APIConfig } from "../../Config";
import { showSuccessToast, showErrorToast } from '../../services/toastService';  // Ensure the path is correct

const UpdatePasswordPage: React.FC = () => {
	const { token } = useParams<"token">();
	const navigate = useNavigate();
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');

	const updatePassword = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (password !== passwordConfirmation) {
			showErrorToast('Passwords do not match.');
			return;
		}

		try {
			const response = await axios.patch(`${APIConfig.baseURL}/password_resets/${token}`, {
				password,
				password_confirmation: passwordConfirmation
			});
			showSuccessToast(response.data.message || 'Password successfully updated');
			setPassword('');  // Clear the password input
			setPasswordConfirmation('');  // Clear the password confirmation input
			navigate('/login');  // Redirect to login page
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errorMessage = err.response?.data?.errors || 'An unexpected error occurred.';
				showErrorToast(errorMessage);
			} else {
				showErrorToast('An unexpected error occurred.');
				console.error('An unexpected error occurred:', err);
			}
		}
	};

	return (
		<div className="min-h-screen flex items-center justify-center bg-gray-100">
			<div className="max-w-md w-full bg-white p-6 rounded-md shadow-md">
				<h2 className="text-xl font-bold mb-4 text-center">Update your Password</h2>
				<form onSubmit={updatePassword} className="space-y-6">
					<div>
						<label className="text-sm font-bold text-gray-600 block">New Password:</label>
						<input
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							className="mt-1 px-3 py-2 bg-gray-200 rounded border border-gray-300 w-full"
						/>
					</div>
					<div>
						<label className="text-sm font-bold text-gray-600 block">Confirm New Password:</label>
						<input
							type="password"
							value={passwordConfirmation}
							onChange={(e) => setPasswordConfirmation(e.target.value)}
							className="mt-1 px-3 py-2 bg-gray-200 rounded border border-gray-300 w-full"
						/>
					</div>
					<button
						type="submit"
						className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
					>
						Update Password
					</button>
				</form>
			</div>
		</div>
	);
};

export default UpdatePasswordPage;
