import axios from 'axios';
import { useCallback } from 'react';
import { showSuccessToast, showErrorToast } from '../services/toastService';
import {APIConfig} from "../Config";

const API_URL = `${APIConfig.baseURL}/registrations`; // Replace with your actual API URL

const useRegister = () => {
	const register = useCallback(async (data: any) => {
		try {
			const response = await axios.post(API_URL, data);
			showSuccessToast('Registration successful');
			return response.data; // This might include user info or a success message.
		} catch (error) {
			if (axios.isAxiosError(error)) {
				// Handling errors returned from the server
				let errorMessage = 'Registration failed'; // Default error message
				if (error.response && error.response.data.data) {
					// If the API returns a plain string in response.data
					if (typeof error.response.data.data === 'string') {
						errorMessage = error.response.data.data;
					}
					// If the API returns an object with 'message' or 'error'
					else if (error.response.data.message) {
						errorMessage = error.response.data.data;
					} else if (error.response.data.data) {
						errorMessage = error.response.data.data;
					}
				}
				const messageString = String(errorMessage[0]);
				showErrorToast(messageString);
				throw new Error(messageString); // Re-throw with server error message
			} else {
				// Handling unexpected errors or when the server is unreachable
				showErrorToast('Registration failed due to network or server error');
				throw new Error('Network or server error occurred during registration');
			}
		}
	}, []);

	return { register };
};

export default useRegister;
