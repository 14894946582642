import React from 'react';
import AppRoutes from "./routes";
import { AuthProvider } from "./api/AuthProvider";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ToastContainer />
      <AppRoutes/>
    </AuthProvider>
  );
}

export default App;
