import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import logo from '../../assets/kenbukailogo_transparent.png';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../api/AuthProvider";
import { showSuccessToast, showErrorToast } from '../../services/toastService'; // Adjust path as necessary
import { Link } from 'react-router-dom';

const Login: React.FC = () => {
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const navigate = useNavigate();
	const { login } = useAuth();

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			navigate('/');
		}
	}, [navigate]);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		console.log('Login Submitted', { email, password });
		try {
			await login(email, password);
			showSuccessToast('Login successfully'); // Show success toast
			navigate('/');
		} catch (error) {
			showErrorToast(error instanceof Error ? error.message : "Invalid Email Password"); // Show error toast
		}
	};

	return (
		<div className="flex items-center justify-center min-h-screen bg-gray-100">
			<div className="w-full max-w-md px-8 py-6 mx-4 mt-4 text-left bg-white shadow-lg">
				<div className="flex justify-center">
					<img src={logo} alt="Logo" className="w-20 h-20" />
				</div>
				<h3 className="mt-4 text-2xl font-bold text-center">Login to your account</h3>
				<form onSubmit={handleSubmit}>
					<div className="mt-4">
						<div>
							<label className="block" htmlFor="email">Email</label>
							<input type="email" placeholder="Email"
							       className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
							       value={email} onChange={e => setEmail(e.target.value)}
							       required />
						</div>
						<div className="mt-4">
							<label className="block" htmlFor="password">Password</label>
							<div className="relative">
								<input type={showPassword ? 'text' : 'password'} placeholder="Password"
								       className="w-full px-4 py-2 mt-0 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
								       value={password} onChange={e => setPassword(e.target.value)}
								       required />
								<button type="button" onClick={() => setShowPassword(!showPassword)}
								        className="absolute right-2 top-2 text-gray-600 focus:outline-none">
									{showPassword ? <FaEyeSlash /> : <FaEye />}
								</button>
							</div>
						</div>
						<div className="flex items-baseline justify-between">
							<button className="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">Login</button>
						</div>
						<div className="text-sm mt-4 text-center">
							<Link to="/reset-password" className="font-bold text-blue-500 hover:text-blue-800">
								Forgot Password?
							</Link>
							<span className="mx-2">|</span>
							<Link to="/register" className="font-bold text-blue-500 hover:text-blue-800">
								Register
							</Link>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Login;
