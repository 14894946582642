import { useState, useCallback, useContext, createContext, ReactNode } from 'react';
import axios from "axios";
import { APIConfig } from "../Config";

interface AuthContextType {
	token: string | null;
	login: (email: string, password: string) => Promise<void>;
	logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider');
	}
	return context;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [token, setToken] = useState<string | null>(localStorage.getItem('token'));

	const login = useCallback(async (email: string, password: string) => {
		try {
			const response = await axios.post(`${APIConfig.baseURL}/login`, {
				email, password
			});
			// Assuming the token is directly in the response body.
			// Adjust accordingly if it's nested within another object.
			const { token } = response.data.data;
			localStorage.setItem('KENBUKAI-TOKEN', token);
			setToken(token);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				// Handling errors specific to axios
				if (error.response) {
					// Server responded with a status outside the 2xx range
					console.error("Data:", error.response.data);
					console.error("Status:", error.response.status);
					console.error("Headers:", error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					console.error("Request:", error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.error("Error message:", error.message);
				}
			} else {
				// Handling non-axios errors
				console.error("Error:", error);
			}
			throw new Error('An error occurred during login');
		}
	}, []);

	const logout = useCallback(() => {
		localStorage.removeItem('KENBUKAI-TOKEN');
		setToken(null);
	}, []);

	return (
		<AuthContext.Provider value={{ token, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};