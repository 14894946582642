import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as farHeart, faThumbsUp as farThumbsUp, faHandRock as farHandRock } from '@fortawesome/free-regular-svg-icons';
import useTrainings from '../../api/useTrainings';

type Activity = {
	id: string;
	fullname: string;
	description: string;
	heartCount: number;
	likeCount: number;
	fistBumpCount: number;
	image_url: string;
};

type ActivityProps = {
	activity: Activity;
	onIncrement: (type: 'heartCount' | 'likeCount' | 'fistBumpCount') => void;
};

const buttonClass = "flex items-center text-gray-700 hover:text-gray-600 transition-transform duration-150 transform hover:scale-110";

// Activity Card Component
const ActivityItem: React.FC<ActivityProps> = ({ activity, onIncrement }) => {
	return (
		<div
			className="bg-white border rounded-lg p-5 shadow-sm hover:shadow-md transition-shadow duration-200 flex items-center space-x-4">
			<div className="relative">
				<img src={activity.image_url} alt={`${activity.fullname}'s profile`}
				     className="w-20 h-20 rounded-full border border-gray-300" style={{ height: "90px", width: "90px" }} />


			</div>
			<div className="flex flex-col flex-grow">
				<h3 className="text-lg font-semibold text-gray-800">{activity.fullname}</h3>
				<p className="text-sm text-gray-600">{activity.description}</p>
				<div className="flex items-center space-x-3 mt-2">
					<button onClick={() => onIncrement('heartCount')}
					        className="flex items-center text-gray-700 hover:text-gray-600 transition-transform duration-150 transform hover:scale-110">
						<FontAwesomeIcon icon={farHeart} className="h-5 w-5 mr-1"/> <span>{activity.heartCount}</span>
					</button>
					<button onClick={() => onIncrement('likeCount')}
					        className="flex items-center text-gray-700 hover:text-gray-600 transition-transform duration-150 transform hover:scale-110">
						<FontAwesomeIcon icon={farThumbsUp} className="h-5 w-5 mr-1"/> <span>{activity.likeCount}</span>
					</button>
					<button onClick={() => onIncrement('fistBumpCount')}
					        className="flex items-center text-gray-700 hover:text-gray-600 transition-transform duration-150 transform hover:scale-110">
						<FontAwesomeIcon icon={farHandRock} className="h-5 w-5 mr-1"/> <span>{activity.fistBumpCount}</span>
					</button>
				</div>
			</div>
		</div>
	);
};


// Main Page Component
const HomePage: React.FC = () => {
	const {trainings, loading, error} = useTrainings();
	const [activities, setActivities] = useState<Activity[]>([]);

	useEffect(() => {
		if (!loading && !error) {
			const initialActivities = trainings.map(training => ({
				id: training.id,
				fullname: training.fullname,
				description: training.description,
				heartCount: 0,
				likeCount: 0,
				fistBumpCount: 0,
				image_url: training.image_url
			}));
			setActivities(initialActivities);
		}
	}, [trainings, loading, error]);

	const incrementCount = (index: number, type: 'heartCount' | 'likeCount' | 'fistBumpCount') => {
		const updatedActivities = activities.map((activity, i) =>
			i === index ? { ...activity, [type]: activity[type] + 1 } : activity
		);
		setActivities(updatedActivities);
	};

	return (
		<div className="container mx-auto max-w-4xl my-10 px-4 lg:px-8">
			{loading ? (
				<div className="text-center text-gray-600">Loading activities...</div>
			) : error ? (
				<div className="text-center text-red-600">{error}</div>
			) : (
				<div className="space-y-6">
					{activities.map((activity, index) => (
						<ActivityItem
							key={activity.id}
							activity={activity}
							onIncrement={(type) => incrementCount(index, type)}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default HomePage;
