import React, { useEffect, useState } from 'react';
import MemberTable from "./MembersTable";
import { useMembers } from "../../api/useMembers";

const MembersPage: React.FC = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState('');
	const perPage = 6;  // Constant if it doesn't change
	const { data, links, loading, error } = useMembers(currentPage, perPage);

	useEffect(() => {
		console.log("Updated Data", data);  // Log updated data
	}, [data]);  // Depend on data so it logs on data changes

	// Handlers for pagination
	const handlePagination = (url: string | undefined) => {
		if (!url) return;
		const urlParams = new URL(url).searchParams;
		const page = urlParams.get('page');
		if (page) setCurrentPage(parseInt(page, 6));
	};

	// Filter data based on search term
	const filteredData = data.filter(member =>
		member.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
		member.email.toLowerCase().includes(searchTerm.toLowerCase())
	);

	return (
		<div
			className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-4xl bg-white shadow-md hover:shadow-lg transition-shadow duration-300 rounded-lg overflow-hidden my-4">
			<h1 className="text-xl sm:text-2xl font-bold text-center my-4">Team Members</h1>
			<input
				type="text"
				placeholder="Search by name or email"
				className="w-full p-2 border rounded-lg mb-4"
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
			/>
			{loading ? (
				<p>Loading...</p>
			) : error ? (
				<p className="text-red-500">{error}</p>
			) : (
				<MemberTable members={filteredData}/>  // Pass fetched data to MemberTable
			)}
			<div className="flex flex-wrap items-center justify-center space-x-1 mt-4">
				<button
					onClick={() => handlePagination(links?.first)}
					className="px-3 py-2 text-sm sm:text-md font-medium text-gray-700 bg-white rounded-md border border-gray-300 hover:bg-gray-100 disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-200 disabled:cursor-not-allowed"
					disabled={!links?.first}
				>
					First
				</button>
				<button
					onClick={() => handlePagination(links?.prev)}
					className="px-3 py-2 text-sm sm:text-md font-medium text-gray-700 bg-white rounded-md border border-gray-300 hover:bg-gray-100 disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-200 disabled:cursor-not-allowed"
					disabled={!links?.prev}
				>
					«
				</button>
				<button
					onClick={() => handlePagination(links?.next)}
					className="px-3 py-2 text-sm sm:text-md font-medium text-gray-700 bg-white rounded-md border border-gray-300 hover:bg-gray-100 disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-200 disabled:cursor-not-allowed"
					disabled={!links?.next}
				>
					»
				</button>
				<button
					onClick={() => handlePagination(links?.last)}
					className="px-3 py-2 text-sm sm:text-md font-medium text-gray-700 bg-white rounded-md border border-gray-300 hover:bg-gray-100 disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-200 disabled:cursor-not-allowed"
					disabled={!links?.last}
				>
					Last
				</button>
			</div>
		</div>
	);
};

export default MembersPage;