import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { showSuccessToast, showErrorToast } from '../../services/toastService';
import useRegister from "../../api/useRegister";
import useBranches from "../../api/useBranches";

const RegisterPage: React.FC = () => {
	const [formData, setFormData] = useState({
		email: '',
		password: '',
		confirmPassword: '',
		branch: '',
		dojo: '',
		dateOfBirth: '',
		fullname: ''
	});
	const navigate = useNavigate();
	const { register } = useRegister();
	const { branches, selectedBranch, dojos, loading, error, selectBranch } = useBranches();

	useEffect(() => {
		if (formData.branch && selectedBranch?.id !== formData.branch) {
			selectBranch(formData.branch);
		}
	}, [formData.branch, selectedBranch, selectBranch]);

	useEffect(() => {
		if (formData.branch && dojos.length > 0) {
			setFormData(prev => ({ ...prev, dojo: '' })); // Reset dojo when branch changes
		}
	}, [formData.branch, dojos]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = event.target;
		setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (formData.password !== formData.confirmPassword) {
			showErrorToast("Passwords do not match");
			return;
		}

		const { confirmPassword, ...dataToSend } = formData;
		try {
			await register(dataToSend);
			navigate('/login');
		} catch (error) {
		}
	};

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error: {error}</p>;

	return (
		<div className="flex items-center justify-center min-h-screen bg-gray-100">
			<div className="w-full max-w-md px-8 py-6 mx-4 mt-4 text-left bg-white shadow-lg">
				<h3 className="text-2xl font-bold text-center">Kyokushin KenbukaiPH</h3>
				<h3 className="text-2xl font-bold text-center">Registration Online</h3>
				<form onSubmit={handleSubmit}>
					<div className="mt-4">
						<label className="block">Fullname</label>
						<input type="text" name="fullname" placeholder="Fullname"
						       className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
						       value={formData.fullname} onChange={handleChange}
						       required />
					</div>
					<div className="mt-4">
						<label className="block">Email</label>
						<input type="email" name="email" placeholder="Email"
						       className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
						       value={formData.email} onChange={handleChange}
						       required />
					</div>
					<div className="mt-4">
						<label className="block">Password</label>
						<input type="password" name="password" placeholder="Password"
						       className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
						       value={formData.password} onChange={handleChange}
						       required />
					</div>
					<div className="mt-4">
						<label className="block">Confirm Password</label>
						<input type="password" name="confirmPassword" placeholder="Confirm Password"
						       className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
						       value={formData.confirmPassword} onChange={handleChange}
						       required />
					</div>
					<div className="mt-4">
							<label className="block">Branch</label>
							<select name="branch" value={formData.branch} onChange={handleChange}
							        className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600">
								<option value="">Select Branch</option>
								{branches.map(branch => (
									<option key={branch.id} value={branch.id}>{branch.name}</option>
								))}
							</select>
					</div>
					<div className="mt-4">
						<label className="block">Dojo</label>
						<select name="dojo" value={formData.dojo} onChange={handleChange}
						        className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
						        required>
							<option value="">Select Dojo</option>
							{dojos.map(dojo => (
								<option key={dojo.id} value={dojo.dojo_id}>{dojo.name}</option>
							))}
						</select>
					</div>
					<div className="mt-4">
						<label className="block">Date of Birth</label>
						<input type="date" name="dateOfBirth"
						       className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
						       value={formData.dateOfBirth} onChange={handleChange}
						       required />
					</div>
					<div className="flex items-center justify-between mt-4">
						<button type="submit" className="px-6 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-900">
							Register
						</button>
					</div>
				</form>
			</div>
		</div>
);
};

export default RegisterPage;

