import React, { ReactNode } from 'react';

interface ModalProps {
	title: string;
	children: ReactNode;
	onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ title, children, onClose }) => {
	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center px-4">
			<div className="relative bg-white rounded-lg shadow-xl">
				<div className="flex justify-between items-start p-5 rounded-t border-b">
					<h3 className="text-xl font-semibold text-gray-900 lg:text-2xl">{title}</h3>
					<button
						className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
						onClick={onClose}
					>
						<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7.707 7.707a1 1 0 011.414-1.414L10 8.586l1.293-1.293a1 1 0 111.414 1.414L11.414 10l1.293 1.293a1 1 0 01-1.414 1.414L10 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L8.586 10 7.293 8.707a1 1 0 010-1.414z" clipRule="evenodd" />
						</svg>
					</button>
				</div>
				<div className="p-6">
					{children}
				</div>
			</div>
		</div>
	);
};

export default Modal;
