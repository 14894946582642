import React from 'react';
import { Link } from 'react-router-dom';
import './MobileMenu.css'; // Import the CSS file

// Define a type for the component props
type MobileMenuProps = {
	isMenuOpen: boolean;
	handleLogout: () => void; // Assuming handleLogout does not take any parameters and does not return anything
	handleToggleMenu: () => void;
};

const MobileMenu: React.FC<MobileMenuProps> = ({ isMenuOpen, handleLogout, handleToggleMenu }) => {
	const menuClass = `mobile-menu ${isMenuOpen ? 'open' : 'closed'}`; // Added 'closed' class for clarity

	return (
		<div className={menuClass} id="mobile-menu">
			<div className="px-2 pt-2 pb-3 space-y-1 menuClass">
				<Link to="/" onClick={handleToggleMenu}
				      className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Home</Link>
				<Link to="/profile" onClick={handleToggleMenu}
				      className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Profile</Link>
				<Link to="/members" onClick={handleToggleMenu}
				      className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Members</Link>
				<Link to="/transactions" onClick={handleToggleMenu}
				      className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Transactions</Link>
				<button onClick={handleLogout}
				        className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium w-full text-left">Logout
				</button>
			</div>
		</div>
	);
};

export default MobileMenu;
