// ToggleMenuButton.tsx
import React from 'react';

interface ToggleMenuButtonProps {
	isMenuOpen: boolean;
	handleToggleMenu: () => void;
}

const ToggleMenuButton: React.FC<ToggleMenuButtonProps> = ({ isMenuOpen, handleToggleMenu }) => {
	return (
		<button
			type="button"
			className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
			aria-controls="mobile-menu"
			aria-expanded={isMenuOpen}
			onClick={handleToggleMenu}
		>
			<span className="sr-only">Open main menu</span>
			{/* Menu open icon, shown when the menu is closed */}
			<svg className={`${isMenuOpen ? 'hidden' : 'block'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
				<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
			</svg>
			{/* Menu close icon, shown when the menu is open */}
			<svg className={`${isMenuOpen ? 'block' : 'hidden'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
				<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
			</svg>
		</button>
	);
};

export default ToggleMenuButton;
