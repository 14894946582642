import React from 'react';

const PageNotFound = () => {
	return (
		<div>
			<h1>PAGES NOT FOUND</h1>
			{/* Settings content goes here */}
		</div>
	);
};

export default PageNotFound;